<template>
  <div class="help position-relative">
    <!-- шапка блока-->
    <div
      class="p-20 mb-20 bg-white border-bottom border-brd-primary position-relative"
    >
      <div class="page-header">
        <h1 class="page-title mb-0">Обучение</h1>
      </div>
    </div>
    <!--контент-->

    <div class="container">
      <div class="row-fluid">
        <ul class="col m-0 p-0">
          <li
            v-for="(video, i) of this.video_list"
            :key="'video_' + i"
            class="bg-white rounded-3 mb-20 p-0"
          >
            <div class="ratio ratio-16x9 mb-1">
              <iframe :src="video.src" :title="video.title" allowfullscreen>
                <!--								<img
									:src="require('@/assets/img/video_mock.png')"
									alt="Заглушка для видео"
									width="400" height="270"
									class="video_mock"
								>-->
              </iframe>
            </div>
            <h2 class="fs-3 fw-semi font-semi px-20 pb-20 pt-10">
              {{ video.title }}
            </h2>
          </li>
        </ul>
        <Pagination
          :initialPage="1"
          :itemsList="this.helpList"
          :labels="labels"
          :pageSize="this.page_size"
          :disableDefaultStyles="true"
          @changePage="changePage"
          :maxPages="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from "../services/CommonService";
import Pagination from "@/components/common/Pagination";
const labels = {
  first: false,
  last: false,
  previous: false,
  next: false,
};
export default {
  name: "help",

  components: {
    Pagination,
  },

  data() {
    return {
      title: "Помощь, обучение",
      page_size: 5,
      page_number: 1,
      helpList: [
        {
          title: "Полный обзор CRM для агентства недвижимости JoyWork",
          src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239093",
        },
		{
			title: "Добавление сотрудников и настройка отделов в CRM для агентства недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239094"
			
		
		},
		{
			title: "Ведение клиентов и воронки продаж в CRM для риэлторов JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239095"
			
		
		},
		{
			title: "Настройка воронок продаж в CRM \"JoyWork\" - оптимизация бизнес-процессов в Агентстве недвижимости",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239096"
			
		
		},
		{
			title: "Настройка отчетов и выгрузок на классифайды в СРМ для агентств недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239114"
			
		
		},
		{
			title: "Управление типами размещений на площадках из СРМ и настройка интеграции по АПИ",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239109"
			
		
		},
		{
			title: "Предмодерация Рекламы Объектов в СРМ для агентств недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239111"
			
		
		},
		{
			title: "Интеграция с чатами Авито и Циан в СРМ для риэлторов JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239119"
			
		
		},
		{
			title: "Импорт объектов на сайт Tilda из СРМ JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239117"
			
		
		},
		{
			title: "Кабинет клиента",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239101"
			
		
		},
		{
			title: "Добавление своих полей в карточку объекта и выгрузка этих полей в xml фиды в CRM JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239108"
			
		
		},
		{
			title: "Автопоиск в CRM для Агентств недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239098"
			
		
		},
		{
			title: "Автоматическое создание Автопоиска в СРМ для недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239099"
			
		
		},
		{
			title: "Удаление водяных знаков с сайтов Авито Циан Яндекс Недвижимость и ДомКлик",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239116"
			
		
		},
		{
			title: "Отправка предложений клиенту на Whats App из CRM для риэлторов JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239103"
			
		
		},
		{
			title: "Раздел Новостройки в CRM",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239115"
			
		
		},
		{
			title: "Создание каталога жилых комплексов, домов и шахматок в CRM системе для девелоперов JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239121"
			
		
		},
		{
			title: "Добавление жилых комплексов и объектов в них в СРМ для недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239112"
			
		
		},
		{
			title: "Создание объектов в жилых комплексах при помощи Exel в CRM системе для девелоперов JoyWork",
			src: "https://vk.com/video_ext.php?oid=-140502045&id=456239137"
			
		
		},
		{
			title: "Раздел заявки в CRM JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239104"
			
		
		},
		{
			title: "Фиксация заявок под объекты компании",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239107"
			
		
		},
		{
			title: "Создание дополнительных полей в карточке Клиентов и Заявок в СРМ для агентств недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239113"
			
		
		},
		{
			title: "Интеграция телефонии в JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239100"
			
		
		},
		{
			title: "Виджет приема звонка в CRM JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239105"
			
		
		},
		{
			title: "Настройка телефонов в рекламных источниках",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239106"
			
		
		},
		{
			title: "Сквозная аналитика",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239097"
			
		
		},
		{
			title: "Отчеты по кастомным полям",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239118"
			
		
		},
		{
			title: "Выставление плана продаж и контроль выполнения, в CRM для недвижимости JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239110"
			
		
		},
		{
			title: "Заказ сведений ЕГРН из CRM системы JoyWork",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239102"
			
		
		},
		{
			title: "Настройка постинга объектов из CRM в VK.com",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239124"
			
		
		},
		{
			title: "Создание и назначение Тегов к Клиентам, Объектам и Заявкам",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239128"
			
		
		},
		{
			title: "Аналитика объектов - конкурентов. Как риэлтору экономить деньги на рекламе?",
			src: "https://vk.ru/video_ext.php?oid=-140502045&id=456239129"
		}
      ],
    };
  },
  methods: {
    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
	changePage(page) {
		this.page_number = page
	}
  },
  computed: {
    video_list() {
      return this.paginate(this.helpList, this.page_size, this.page_number);
    },
  },
  mounted() {
    CommonService.setPageTitle(this.title);
  },
};
</script>

<style>
.video_mock {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>